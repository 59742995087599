import {
  HOME_PATH,
  RESOURCES_PATH,
  DAILY_JOURNEY_PATH,
  WATCH_PATH,
  PlAYLIST_PATH,
  CATEGORY_PATH,
  SEARCH_PATH,
  DAILY_VIDEO_PATH,
  SETTINGS_PATH,
  DEVELOPER_PATH,
  COMMUNICATIONS_OPT_IN_PATH,
  REGISTER_PATH,
  REGISTER_COMPANY_SELECTOR_PATH,
  LIVE_VIDEO_PATH,
  BENEFITS_PATH,
  RESOURCES_PATH_MODULE,
  RESOURCES_PATH_DIVISION,
  RESOURCES_PATH_ARTICLE,
  RESOURCES_PATH_CATEGORY,
  LANGUAGES_PATH,
  PUBLIC_WATCH_PATH,
  SSO_LOGIN_PATH, SEARCH_PATH_QUERY,
  PARTIAL_WATCH_PATH,
  CHAMPION_OPT_IN_PATH,
  PARTICIPANT_OPT_IN_PATH,
  TEACHER_PATH,
  MY_LIST_PATH,
  TOPIC_PAGE_PATH,
  PERSONAL_PLAN_PATH,
  PROFILE_PATH,
  LANDING_PAGE_PATH,
  ASSESSMENT_PATH,
  SCHEDULE_APPOINTMENT_PATH,
  HR_HUB_PAGE_PATH,
  ASK_A_QUESTION_PATH,
  EAP_SUPPORT_PHONES_PATH
} from "../../utils/utils";
import { IonModal, IonRouterOutlet } from "@ionic/react";
import React, {useContext, useEffect, useState} from "react";
import {Route, Redirect} from "react-router-dom";
import {VideoPage} from "../../pages/VideoPage/VideoPage";
import {DailyJourneyPage} from "../../pages/DailyJourneyPage/DailyJourneyPage";
import {BenefitsPage} from "../../pages/BenefitsPage/BenefitsPage";
import {PlaylistVideoPage} from "../../pages/PlaylistVideoPage/PlaylistVideoPage";
import {SideMenu} from "../SideMenu/SideMenu";
import {CategoryPage} from "../../pages/CategoryPage/CategoryPage";
import {SearchPage} from "../../pages/SearchPage/SearchPage";
import {DailyVideoPage} from "../../pages/DailyVideoPage/DailyVideoPage";
import {SettingsPage} from "../../pages/SettingsPage/SettingsPage";
import {RegisterPage} from "../../pages/RegisterPage/RegisterPage";
import {LiveVideoPage} from "../../pages/LiveVideoPage/LiveVideoPage";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { HomePage } from "../../pages/HomePage/HomePage";
import {CommunicationsOptInPage} from "../../pages/CommunicationsOptInPage/CommunicationsOptInPage";
import {ChampionRegistrationPage} from "../../pages/ChampionRegistrationPage/ChampionRegistrationPage";
import {ParticipantRegistrationPage} from "../../pages/ParticipantRegistrationPage/ParticipantRegistrationPage";
import ClientConfig from "../../utils/ClientConfig";
import {ContentDivisionPage} from "../../pages/ResourcesPages/ContentDivisionPage";
import {ContentModulePage} from "../../pages/ResourcesPages/ContentModulePage";
import {ContentArticlePage} from "../../pages/ResourcesPages/ContentArticlePage";
import {ContentCategoryPage} from "../../pages/ResourcesPages/ContentCategoryPage";
import {LanguagesPage} from "../../pages/LanguagesPage/LanguagesPage";
import { PublicVideoPage } from "../../pages/PublicVideoPage/PublicVideoPage";
import {SsoLoginPage} from "../../pages/SsoLoginPage/SsoLoginPage";
import { MindfulBreak } from "../MindfulBreak/MindfulBreak";
import {ScheduleAppointmentPage} from "../../pages/ScheduleAppointmentPage/ScheduleAppointmentPage";
import {AskAQuestionPage} from "../../pages/AskAQuestionPage/AskAQuestionPage";
import {TeacherPage} from "../../pages/TeacherPage/TeacherPage";
import {EapSupportPhones} from "../EapSupportPhones/EapSupportPhones";
import {MyListPage} from "../../pages/MyListPage/MyListPage";
import {LandingPage} from "../../pages/LandingPage/LandingPage";
import {PersonalPlanPage} from "../../pages/PersonalPlanPage/PersonalPlanPage";
import { CopyToClipboardModal } from "../CopyToClipboard/CopyToClipboardModal";
import { InstantHelpSlider } from "../InstantHelpSlider/InstantHelpSlider";
import { DeveloperPage } from "../../pages/DeveloperPage/DeveloperPage";
import {useLocation} from "react-router";
import {ProfilePage} from "../../pages/ProfilePage/ProfilePage";
import {AssessmentPage} from "../../pages/AssessmentPage/AssessmentPage";
import {AssessmentModal} from "../Assessment/AssessmentModal";
import {HrHubPage} from "../../pages/HrHubPage/HrHubPage";
import { CommunicationsOptInModal } from "../CommunicationsOptIn/CommunicationsOptInModal";
import { RegisterCompanySelectorPage } from "../../pages/RegisterPage/RegisterCompanySelectorPage";
import { PostRegistrationQuestionsPage } from "../../pages/PostRegistrationQuestionsPage/PostRegistrationQuestionsPage";
import { EapSupportPhonesPage } from "../../pages/EapSupportPhonePage/EapSupportPhonePage";
import { Feature } from "../Feature/Feature";
import { TopicPage } from "../../pages/TopicPage/TopicPage";


export const LoggedInNavigation: React.FC = () => {

  const {
    isMindfulBreakModalOpen,
    isDownloadMobileAppModalOpen,
    currentUser,
    fullLanguageSupport,
    setIsMindfulBreakModalOpen,
    isShareRegistrationLinkOpen,
    setIsShareRegistrationLinkOpen,
    isInstantHelpSliderOpen,
    setIsInstantHelpSliderOpen,
    isMobileApp,
    isCommunicationsOptInModalOpen,
  } = useContext(ApplicationContext);

  const needsPostRegistrationQuestions = (currentUser?.company?.requiresDepartment && currentUser?.company?.departments && currentUser?.company?.departments?.length > 0 && currentUser?.departmentId === null) || (currentUser?.company?.requiresLocation && currentUser?.company?.locations && currentUser?.company?.locations?.length > 0 && currentUser?.locationId === null) || (currentUser?.company?.requiresTier && currentUser?.company?.tiers && currentUser?.company?.tiers?.length > 0 && currentUser?.tierId === null);
  const location = useLocation();
  const monthlyAssessmentIsAllowed: boolean = !needsPostRegistrationQuestions && location.pathname === HOME_PATH;
  const [shareRegistrationLink, setShareRegistrationLink] = useState<string>("");

  const handleMindfulBreakModalDismiss = async () => {
    //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
    await new Promise(r => setTimeout(r, 10));
    setIsMindfulBreakModalOpen(false);
  }

  const handleShareRegistrationLinkModalDismiss = async () => {
    //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
    await new Promise(r => setTimeout(r, 10));
    setIsShareRegistrationLinkOpen(false);
  }

  const handleInstantHelpModalDismiss = async () => {
      //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
      await new Promise(r => setTimeout(r, 10));
      setIsInstantHelpSliderOpen(false);
  }

  useEffect(() => {
    const urlBase = currentUser?.company?.customWebUrlRoot ? currentUser?.company?.customWebUrlRoot : ClientConfig.newFrontendRoot;
    const shareUrl = `${urlBase}/register/${currentUser?.company?.friendsAndFamilyCompanyCode}`;
    setShareRegistrationLink(shareUrl);
  }, [currentUser]);

    return(
        <>

            <SideMenu hideMenuButtons={needsPostRegistrationQuestions} />

            <IonRouterOutlet id="main-content" className={`${isMindfulBreakModalOpen || isDownloadMobileAppModalOpen || isCommunicationsOptInModalOpen ? "blur-content" : "unblur-content"}`} animated={!!isMobileApp}>
              <Route path={WATCH_PATH} component={VideoPage} exact={true} />
              <Route path={PARTIAL_WATCH_PATH} component={VideoPage} exact={true} />
              <Route path={PlAYLIST_PATH} component={PlaylistVideoPage} exact={true} />
              <Route path={CATEGORY_PATH} component={CategoryPage} exact={true} />
              <Route path={`${DAILY_JOURNEY_PATH}/:id?`} component={DailyJourneyPage} exact={true} />
              <Route path={DAILY_VIDEO_PATH} component={DailyVideoPage} exact={true} />
              <Route path={BENEFITS_PATH} component={BenefitsPage} exact={true} />
              <Route path={RESOURCES_PATH} component={ContentDivisionPage} exact={true} />
              <Route path={RESOURCES_PATH_DIVISION} component={ContentDivisionPage} exact={true} />
              <Route path={RESOURCES_PATH_MODULE} component={ContentModulePage} exact={true} />
              <Route path={RESOURCES_PATH_CATEGORY} component={ContentCategoryPage} exact={true}/>
              <Route path={RESOURCES_PATH_ARTICLE} component={ContentArticlePage} exact={true}/>
              <Route path={LIVE_VIDEO_PATH} component={LiveVideoPage} exact={true} />
              <Route path={SEARCH_PATH} component={SearchPage} exact={true} />
              <Route path={SEARCH_PATH_QUERY} component={SearchPage} exact={true} />
              <Route path={HOME_PATH} component={needsPostRegistrationQuestions ? PostRegistrationQuestionsPage : fullLanguageSupport ? HomePage : ContentDivisionPage} exact={true} />
              <Route path={SETTINGS_PATH} component={SettingsPage} exact={true} />
              <Route path={DEVELOPER_PATH} component={DeveloperPage} exact={true} />
              <Route path={LANGUAGES_PATH} component={LanguagesPage} exact={true} />
              <Route path={REGISTER_PATH} component={RegisterPage} exact={true}/>
              <Route path={REGISTER_COMPANY_SELECTOR_PATH} component={RegisterCompanySelectorPage} exact={true}/>
              <Route path={COMMUNICATIONS_OPT_IN_PATH} component={CommunicationsOptInPage} exact={true}/>
              <Route path={CHAMPION_OPT_IN_PATH} component={ChampionRegistrationPage} exact={true}/>
              <Route path={PARTICIPANT_OPT_IN_PATH} component={ParticipantRegistrationPage} exact={true}/>
              <Route path={PUBLIC_WATCH_PATH} component={PublicVideoPage} exact={true}/>
              <Route path={SSO_LOGIN_PATH} component={SsoLoginPage} exact={true}/>
              <Route path={TEACHER_PATH} component={TeacherPage} exact={true} />
              <Route path={MY_LIST_PATH} component={MyListPage} exact={true}/>
              <Route path={LANDING_PAGE_PATH} component={LandingPage} exact={true}/>
              <Route path={PERSONAL_PLAN_PATH} component={PersonalPlanPage} exact={true} />
              <Route path={PROFILE_PATH} component={ProfilePage} exact={true}/>
              <Route path={ASSESSMENT_PATH} component={AssessmentPage} exact={true} />
              <Route path={SCHEDULE_APPOINTMENT_PATH} component={ScheduleAppointmentPage} exact={true} />
              <Route path={ASK_A_QUESTION_PATH} component={AskAQuestionPage} exact={true} />
              <Route path={HR_HUB_PAGE_PATH} component={HrHubPage} exact={false} />
              <Route path={EAP_SUPPORT_PHONES_PATH} component={EapSupportPhonesPage} exact={true} />
              <Route path={TOPIC_PAGE_PATH} component={TopicPage} exact={true} />
              <Route>
                <Redirect to={HOME_PATH} exact/>
              </Route>
            </IonRouterOutlet>
            <IonModal
                    className={"mindful-break-modal"}
                    showBackdrop={false}
                    isOpen={isMindfulBreakModalOpen}
                    onWillDismiss={handleMindfulBreakModalDismiss}>
                    <MindfulBreak isComponentVisible={isMindfulBreakModalOpen}/>
            </IonModal>
            <InstantHelpSlider isOpen={isInstantHelpSliderOpen} handleModalDismiss={handleInstantHelpModalDismiss} />
            <CopyToClipboardModal
                title="Friends and Family Registration Link"
                link={shareRegistrationLink}
                isModalOpen={isShareRegistrationLinkOpen}
                dismissModal={handleShareRegistrationLinkModalDismiss}
            />
            <CommunicationsOptInModal />

            <Feature feature='assessment'>
              {currentUser && 
                <AssessmentModal monthlyAssessmentIsAllowed={monthlyAssessmentIsAllowed} hasSeenMonthlyAssessment={currentUser.hasSeenMonthlyAssessment} user={currentUser}/>}
            </Feature>
              
      </>
    );
};
