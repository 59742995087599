import { IonContent, IonFooter, IonIcon, IonModal } from '@ionic/react';
import React, { useContext } from 'react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import { useTranslation } from 'react-i18next';
import PhoneBlack  from '../../assets/images/phone-black.svg';
import { HasFeatureEnabled } from '../Feature/Feature';
import './NegativeCheckInPromptModal.scss';
import ScheduleAppointmentButton from '../ScheduleAppointment/ScheduleAppointmentButton';
import { ApiAcuityAppointment, GetCompanyBenefitsInfoResponse } from '../../utils/ApiTypes';
import { EAP_SUPPORT_PHONES_PATH, SCHEDULE_APPOINTMENT_PATH } from '../../utils/utils';
import { useHistory } from 'react-router';
import CallSupportButton from '../CallSupport/CallSupportButton';
import AskAQuestionButton from '../AskAQuestion/AskAQuestionButton';
import { ExitButton } from '../ExitButton/ExitButton';

interface NegativeCheckInPromptModalProps {
    isOpen: boolean;
    appointment?: ApiAcuityAppointment;
    benefits: GetCompanyBenefitsInfoResponse;
    handleClose: () => void;
}

export const NegativeCheckInPromptModal: React.FC<NegativeCheckInPromptModalProps> = (props: NegativeCheckInPromptModalProps) => {

    const { currentUser, handleUserError } = useContext(ApplicationContext);
    const { t } = useTranslation();
    const history = useHistory();

    function handleScheduleAppointmentButton() {
        if(currentUser && currentUser.company.type === "trial"){
            handleUserError(t("Schedule Appointment is not available for Trial users."), " ");
        }
        else {
            history.push(SCHEDULE_APPOINTMENT_PATH);
        }
    }

    return (
        <IonModal 
            onWillDismiss={props.handleClose}
            isOpen={props.isOpen}
            className="negative-check-in-prompt-modal">
            <IonContent className="negative-check-in-prompt-content">
                <div className="exit-button-container">
                    <ExitButton onClick={props.handleClose}/>
                </div>
                <div className="negative-check-in-prompt-container">
                    <div className="icon-container"><IonIcon src={PhoneBlack} className={"negative-check-in-prompt-icon"}/></div>
                    <p className="negative-check-in-prompt-title header-4">{t("Important Note")}</p>
                    <p className="negative-check-in-prompt-text body-medium">
                        {t("Everyone has tough days sometimes. But we noticed from your check-in that you’ve been feeling pretty down lately.")}
                    </p>
                    <p className="negative-check-in-prompt-text body-medium">
                        {t("If you’d like to connect with a Master's Level Clinician for mental health support, simply click the “Call Us” button below.")}
                    </p>
                    <p className="negative-check-in-prompt-text body-medium">
                        {t("All support is free and confidential.")}
                    </p>
                    <div className="proactive-care-buttons-wrapper">
                        <div className="proactive-care-buttons-container">
                            {props.benefits.supportPhones.length > 0 &&
                                <CallSupportButton 
                                    className='proactive-care-button header-6-variant'
                                    benefitsInfo={props.benefits}
                                />
                            }

                            {(HasFeatureEnabled("talk-to-someone-schedule-appointment") || HasFeatureEnabled("talk-to-someone-schedule-wpo-appointment")) &&
                                <ScheduleAppointmentButton
                                    onClick={handleScheduleAppointmentButton} 
                                    className="proactive-care-button header-6-variant"
                                    hasAppointmentScheduled={!!props.appointment}/>
                            }

                            <AskAQuestionButton 
                                className='proactive-care-button header-6-variant'
                                iconClassName={'benefits-icon'}
                            />
                        </div>
                    </div>
                
                    
                </div>
            </IonContent>    
            <IonFooter className="negative-check-in-prompt-footer">
                <div className="proactive-care-buttons-container">
                    {props.benefits.supportPhones.length > 0 &&
                        <CallSupportButton 
                            className='proactive-care-button header-6-variant'
                            benefitsInfo={props.benefits}
                        />
                    }

                    {(HasFeatureEnabled("talk-to-someone-schedule-appointment") || HasFeatureEnabled("talk-to-someone-schedule-wpo-appointment")) &&
                        <ScheduleAppointmentButton
                            onClick={handleScheduleAppointmentButton}
                            className="proactive-care-button header-6-variant"
                            hasAppointmentScheduled={!!props.appointment}/>
                    }


                    <AskAQuestionButton 
                        className='proactive-care-button header-6-variant'
                        iconClassName={'benefits-icon'}
                    />
                </div>
            </IonFooter>
        </IonModal>
    );
};

